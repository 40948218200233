exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-404-orders-tsx": () => import("./../../../src/templates/404-orders.tsx" /* webpackChunkName: "component---src-templates-404-orders-tsx" */),
  "component---src-templates-404-tsx": () => import("./../../../src/templates/404.tsx" /* webpackChunkName: "component---src-templates-404-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-magic-toys-tsx": () => import("./../../../src/templates/magic-toys.tsx" /* webpackChunkName: "component---src-templates-magic-toys-tsx" */),
  "component---src-templates-order-detail-tsx": () => import("./../../../src/templates/order-detail.tsx" /* webpackChunkName: "component---src-templates-order-detail-tsx" */),
  "component---src-templates-tool-color-picker-tsx": () => import("./../../../src/templates/tool-color-picker.tsx" /* webpackChunkName: "component---src-templates-tool-color-picker-tsx" */),
  "component---src-templates-tool-questionnaire-tsx": () => import("./../../../src/templates/tool-questionnaire.tsx" /* webpackChunkName: "component---src-templates-tool-questionnaire-tsx" */),
  "component---src-templates-tools-tsx": () => import("./../../../src/templates/tools.tsx" /* webpackChunkName: "component---src-templates-tools-tsx" */)
}

