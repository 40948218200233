module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","name":"狐妖手作","short_name":"狐妖手作","description":"狐狸搓一搓 变娃快乐多","start_url":"/","background_color":"#222222","theme_color":"#0d0d0d","display":"standalone","lang":"zh-Hans","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c1a8db8d11ef4363542504c88fd5fb0d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/web/frontend/src/layouts/index.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/404-orders/*"],"workboxConfig":{"runtimeCaching":[{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":6}},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":6}},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":6}},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":6}},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":6}},{"urlPattern":{},"handler":"NetworkOnly"}]}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
